<template>
    <div class="container">
        <qy-top-nav title="评价管理"/>
        <van-cell-group inset>
            <van-row>
                <van-col span="8">
                    <van-image
                            round
                            width="35"
                            height="35"
                            :src="getImgUrl(user.avator)"
                    />
                    <span style="position: relative;top:-12px;left: 10px;">{{user.name}}</span>
                </van-col>
                <van-col span="16">
                    <van-button type="info" plain hairline round size="mini" icon="edit" style="float: right;top:8px;"
                                @click="editComment">写评价
                    </van-button>
                </van-col>
            </van-row>
        </van-cell-group>

        <van-tabs v-model="active" @click="onClick">
            <van-tab title="全部评价" name="1">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :offset="offset"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <van-cell-group inset v-for="item in dataList">
                        <van-cell>
                            <!--评价商品与评价级别-->
                            <div>
                                <div class="comment-goods">
                                    <div class="block-inline img-block">
                                        <van-image
                                                alt="商品logo"
                                                width="50"
                                                height="50"
                                                :src="getImgUrl(item.goodsLogo)"
                                        />
                                    </div>
                                    <div class="block-inline name-block">
                                        {{item.goodsName.length > 15 ? item.goodsName.substring(0,15).concat('...'): item.goodsName}}
                                    </div>
                                    <div class="block-inline time-block">
                                        {{item.commentTime}}
                                    </div>
                                </div>

                                <div class="comment-level">
                                    <van-rate v-model="item.commentLevel" :size="14" readonly/>
                                    <span>{{item.goodsInstanceRemark}}</span>
                                </div>
                            </div>
                            <!--评价内容-->
                            <div class="comment">
                                <span>{{item.comment}}</span>
                            </div>
                            <!--评价图片-->
                            <div class="comment-imgs" v-for="img in item.commentImgs">
                                <van-image
                                        width="80"
                                        height="55"
                                        :src="getImgUrl(img)"
                                />
                            </div>
                        </van-cell>
                    </van-cell-group>
                </van-list>

            </van-tab>

            <van-tab title="有图评价" name="2">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :offset="offset"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <van-cell-group inset v-for="item in dataList">
                        <van-cell>
                            <!--评价商品与评价级别-->
                            <div>
                                <div class="comment-goods">
                                    <div class="block-inline img-block">
                                        <van-image
                                                alt="商品logo"
                                                width="50"
                                                height="50"
                                                :src="getImgUrl(item.goodsLogo)"
                                        />
                                    </div>
                                    <div class="block-inline name-block">
                                        {{item.goodsName.length > 15 ? item.goodsName.substring(0,15).concat('...'): item.goodsName}}
                                    </div>
                                    <div class="block-inline time-block">
                                        {{item.commentTime}}
                                    </div>
                                </div>

                                <div class="comment-level">
                                    <van-rate v-model="item.commentLevel" :size="14" readonly/>
                                    <span>{{item.goodsInstanceRemark}}</span>
                                </div>
                            </div>
                            <!--评价内容-->
                            <div class="comment">
                                <span>{{item.comment}}</span>
                            </div>
                            <!--评价图片-->
                            <div class="comment-imgs" v-for="img in item.commentImgs">
                                <van-image
                                        width="80"
                                        height="55"
                                        :src="getImgUrl(img)"
                                />
                            </div>
                        </van-cell>
                    </van-cell-group>
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
    import request from "@/utils/request";
    export default {
        name: "MyComment",
        components: {},
        data() {
            return {
                loading: false,
                finished: false,
                offset:50,
                active: 1,
                pageSize: 10,
                pageNum: 1,
                user: {
                    avator: 'https://img01.yzcdn.cn/vant/cat.jpeg',
                    name: '柳神'
                },
                dataList: [],
            }
        },
        methods: {
            onLoad() {
                let isLogin = this.isLogin();
                if (!isLogin) {
                    this.$router.push("/login");
                    return
                }
                this.doRequestComment();
            },
            onClick(name, title) {
                this.loading = false;
                this.dataList = [];
                if (this.finished) {
                    this.finished = false;
                } else {
                    this.doRequestComment();
                }
            },
            editComment() {
                this.$router.push("/orderList?status=3");
            },
            doRequestComment() {
                let that = this;
                const db = {
                    imageNot: this.active,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                };
                request.post("/api/comment/queryMyCommentList", db).then(
                    res => {
                        if (res.code == 0) {
                            let data = res.data;
                            let hasNextPage = data.hasNextPage;
                            this.user.avator = data.avator;
                            this.user.name = data.username;
                            let list = data.list
                            list.forEach(item => {
                                if (item.commentImg) {
                                    item.commentImgs = item.commentImg.split(',');
                                    delete item.commentImg;
                                } else {
                                    item.commentImgs = [];
                                }
                            });
                            if (list.length > 0) {
                                that.dataList = that.dataList.concat(list);
                            }
                            if (!hasNextPage) {
                                that.finished = true;
                                that.loading = false;
                            } else {
                                that.finished = false;
                                that.loading = false;
                                that.pageNum++;
                            }
                        } else {
                            that.finished = true;
                        }
                    });
            },
        }
    }
</script>
<style type="text/css" scoped="">
    div.container {
        margin: 0px;
        padding: 0px;
        font-size: 14px;
    }
    .img-block {
        width: 20%;
    }
    .name-block {
        width: 52%;
        overflow: hidden;
    }
    .time-block {
        width: 28%;
    }
    .block-inline {
        height: 50px;
        display: inline-block;
        line-height: 50px;
        float: left;
    }
    .comment-goods img {
        width: 50px;
        height: 50px;
    }

    .comment-imgs {
        display: inline-block;
        margin-right: 3px;
    }
    .comment-imgs img {
        width: 85px;
        height: 65px;
        padding: 5px 5px;
    }
    .comment-level span {
        position: relative;
        left: 15px;
    }
</style>